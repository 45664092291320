import React, {useEffect, useState} from "react";
import Popup from "../ui/popup/Popup";
import {isBrowser, validateEmail} from "../../utils/articleUtil";
import {STRINGS} from "../../constants/strings";
import {URL} from "../../constants/urls";

function EbookPopup(props) {
    const id = "ebook-pupup";
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [tnc, setTnC] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);
    const [displayImage, setDisplayImage] = useState(false);

    const onClosePopup = () => {
        document.getElementById(id).classList.add("invisible");
    }

    const attrObserver = isBrowser() && new MutationObserver((mutations) => {
        mutations.forEach(mu => {
            if (mu.type !== "attributes" && mu.attributeName !== "class") return;
            setDisplayImage(!mu.target.classList.contains("invisible"));
        });
    });

    useEffect(() => {
        if (!isBrowser()) {
            return;
        }
        const element = document.getElementById(id);
        attrObserver.observe(element, {attributes: true})
    }, []);

    const onActionClick = async (action) => {
        switch (action) {
            case 'no':
                onClosePopup();
                break;
            case 'yes':
                if(validateForm()) {
                    setLoading(true);
                    fetch(`${URL.AGE_HOLDINGS}/api/subscribe?name=${name}&email=${email}&book=ebook4&marketing=${marketing}&website=${STRINGS.SUBSCRIPTION_TAG}`)
                        .then(resp => resp.json())
                        .then(res => {
                            setLoading(false);
                            alert('All set, your personalised download link is being setup and sent to your email as you read these words :-)');
                            onClosePopup();
                        });
                }
                break;
        }
    }

    const validateForm = () => {
        if (!name || name.trim() === '') {
            setError('Please enter valid name');
            return false;
        }
        if (!email || !validateEmail(email)) {
            setError('Please enter valid email');
            return false;
        }
        if (!tnc) {
            setError('Please accept Pension Times’ Terms and Privacy Policy');
            return false;
        }
        return true;
    }

    return (
        <Popup id={id} onClose={onClosePopup} customClass={props.className} containerClass={'ebook-popup bg-denim-300 rounded-2xl'} data-datocms-noindex closeIcon={true}>
            <div className={"lg:flex h-full relative "}>
                <div className={"close-icon bg-denim-300 absolute rounded-full text-3xl text-white font-bold text-center cursor-pointer"} onClick={onClosePopup}>X</div>
                {displayImage && <div className={"lg:flex-1 bg-ebook lg:bg-ebook-lg xl:bg-ebook-xl lg:max-w-7/20 w-full min-h-screen-27 bg-cover bg-center rounded-t-2xl lg:rounded-l-2xl lg:rounded-t-none"}/>}
                <div className={"lg:flex-1 px-4 md:px-10 text-white"}>
                    {/*<div className={"text-center lg:mt-8"}>
                        <img className={"ebook-logo"} src={'images/logos/pt-white-logo.png'}/>
                    </div>*/}
                    <div className={"lg:mt-8 text-xl lg:text-3xl xl:text-4xl my-2 uppercase text-center font-bold leading-snug"}>
                        Everything you need to know about pensions, in one place. Get our FREE Ultimate Guide to Pensions now!
                    </div>
                    <div className={"xs:my-4"}>
                        {error && <div className={'error-message'}>{error}</div>}
                        <input type="text"
                               placeholder="Enter your first name*"
                               value={name}
                               onChange={(event) => {
                                   setError(null);
                                   setName(event.target.value);
                               }}
                               className={"popup-input xs:mb-6 mb-2"}
                        />
                        <input type="email"
                               placeholder="Enter your email*"
                               value={email}
                               onChange={(event) => {
                                   setError(null);
                                   setEmail(event.target.value)
                               }}
                               className={"popup-input"}
                        />
                    </div>
                    <div className={'flex items-center italic font-arial'}>
                        <div><input type="checkbox" id="tnc" defaultChecked={tnc} onChange={() => setTnC(!tnc)}/></div>
                        <label htmlFor="tnc">
                            I accept and acknowledge Pension Times’ <a href="/website-terms-of-use" target="_blank" className={"underline"}>
                            Terms</a> and <a href="/website-privacy-policy" target="_blank" className={"underline"}>Privacy Policy</a>
                        </label>
                    </div>
                    <div className={'flex italic font-arial xs:mt-4'}>
                        <div><input type="checkbox" id="marketing" defaultChecked={marketing} onChange={() => setMarketing(!marketing)}/></div>
                        <label htmlFor="marketing">
                            I’m happy for Pension Times to send me selected marketing information which may be of interest to me.
                        </label>
                    </div>
                    <div className={'xs:flex xs:mt-6 xs:mb-10 font-helvetica text-2xl'}>
                        <button onClick={() => {onActionClick('no');}}
                                style={{backgroundColor: "#13508B"}}
                                className={'flex-1 rounded-lg xs:py-4 mr-4 mb-2 w-full'}>No, Thanks</button>
                        <button onClick={() => {onActionClick('yes');}}
                                style={{backgroundColor: "#FAC904", color: "#13508B"}}
                                className={'flex-1 rounded-lg xs:py-4 mb-2 w-full'} disabled={loading}>
                            Send me my E-book
                            {loading && (
                                <img
                                    src="/images/loading.svg"
                                    style={{ marginLeft: "5px" }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default EbookPopup
